import React, { useCallback, useEffect } from 'react';
import styled from '@emotion/styled';
import { RouteComponentProps } from '@reach/router';
import { useDispatch } from 'react-redux';
import { navigate } from 'gatsby-link';
import { get } from 'lodash';

import PublicLayout from '../layout/public-layout';
import EmailVerificationConfirmation from '../components/user/auth/email-verification-confirmation';
import {
  FORGOT_PASSWORD_URL,
  LOG_IN_URL,
  PASSWORD_RESET_CONFIRMATION,
  PASSWORD_RESET_CONFIRMATION_URL,
} from '../constants';
import { resetErrorAction } from '../state/actions/auth';
import { navigateTo } from '../state/actions/routing';
import SEO from '../components/seo';

const Container = styled.div`
  margin: 0 auto;
  max-width: 960px;
  padding: 30px;
`;

export default function PasswordResetConfirmation({ location }: RouteComponentProps<{}>) {
  const userId = get(location, 'state.userId');
  const { pathname } = location || {};
  const dispatch = useDispatch();

  useEffect(() => {
    let redirectPath;
    if (!userId && pathname == PASSWORD_RESET_CONFIRMATION_URL) {
      redirectPath = FORGOT_PASSWORD_URL;
    }

    if (redirectPath) {
      dispatch(navigateTo(redirectPath));
    }
  }, [pathname, dispatch, userId]);

  const handleSignIn = useCallback(() => {
    dispatch(resetErrorAction(LOG_IN_URL));
    navigate(LOG_IN_URL);
  }, [dispatch]);

  return (
    <div>
      <SEO title="Sign In" />;
      <PublicLayout>
        <Container>
          <EmailVerificationConfirmation
            onButtonClick={handleSignIn}
            headingText={PASSWORD_RESET_CONFIRMATION.DISPLAY_MESSAGE}
            buttonText={PASSWORD_RESET_CONFIRMATION.BUTTON_TEXT}
          />
        </Container>
      </PublicLayout>
    </div>
  );
}
